import { useEffect } from 'react'
import type { SetString } from '../../../_types'

export type Storage = {
  type: 'local' | 'session'
  key: string
}

export function useStorage(setValue: SetString, storage?: Storage) {
  useEffect(() => {
    if (storage) {
      const { type, key } = storage
      if (type === 'local') {
        setValue(localStorage.getItem(key) || '')
      } else if (type === 'session') {
        setValue(sessionStorage.getItem(key) || '')
      }
    }
  })

  const saveInStorage = (value: string) => {
    if (storage) {
      const { type, key } = storage
      if (type === 'local') {
        localStorage.setItem(key, value)
      } else if (type === 'session') {
        sessionStorage.setItem(key, value)
      }
    }
  }

  return saveInStorage
}
