import { useRouter } from 'next/router'
import { useAppSelector as useSel } from '@root/store'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import {
  selectSuperChannelByOrgId,
  selectWhiteLabelByChannelId,
} from '@store/organisation-slice/_selectors'
import { MouseEvent } from 'react'

export interface routerProps {
  channel_pathname?: string
}

export default function UseSelectWhiteLabelData() {
  const router = useRouter()
  const { channel_pathname }: routerProps = router.query
  // Add to lowercase to avoid case sensitivity when a user uses
  // a capital letter in the url when using router.query
  // Eg: user types vidzing.tv/Cheerbrandz instead of vidzing.tv/cheerbrandz
  let channelName = ''
  if (channel_pathname) {
    channelName = channel_pathname.toLowerCase()
  }
  const channelId = useSel(selectChannelPropByChannelPathname(channelName)('channelId'))
  const organisationIdFromChannel = useSel(
    selectChannelPropByChannelPathname(channelName)('organisation'),
  )?.organisationId
  const whiteLabel = useSel(selectWhiteLabelByChannelId(channelId))
  const superChannel = useSel(selectSuperChannelByOrgId(organisationIdFromChannel))

  // Function to route to super channel if it exists. To be used on the white label header
  const routeToSuperChannel = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (superChannel) {
      router.push(`/${superChannel.channelUrl}`)
    }
  }

  return {
    whiteLabel,
    channelName,
    channelId,
    organisationIdFromChannel,
    dataLoaded: whiteLabel !== undefined,
    routeToSuperChannel,
  }
}
