import getCustomChannelData from '@root/utils/get-custom-channel-data'
import UseSelectWhiteLabelData from 'hooks/white-label/use-select-white-label-data'
import { AUTH_URL } from '@root/constants'

export default function useGetSignupUrl(channelSlug: string) {
  const customPageData = getCustomChannelData(channelSlug)

  // Determinate if we need custom layout or not
  const isCustomLayout = customPageData !== undefined

  // Get white label
  const { whiteLabel, channelName } = UseSelectWhiteLabelData()
  const hasWhiteLabel = !!whiteLabel

  const signupUrl = isCustomLayout
    ? customPageData.path + '/signup'
    : hasWhiteLabel && channelName
      ? `/${channelName}/signup`
      : AUTH_URL

  const loginUrl = isCustomLayout
    ? customPageData.path + '/login'
    : hasWhiteLabel && channelName
      ? `/${channelName}/login`
      : AUTH_URL

  // If the channel has a custom layout, we need to use the custom layout path.
  // Otherwise, use the channel pathname + billing
  const billingUrl = isCustomLayout ? customPageData.path + '/billing' : `/${channelName}/billing`

  return { signupUrl, loginUrl, billingUrl, isCustomLayout, hasWhiteLabel }
}
