import style from './common.module.css'

export type Props = {
  className?: string
  onClick: () => void
}

export function CloseButton({ className, onClick }: Props): JSX.Element {
  return (
    <button className={[style.button, className].join(' ')} onClick={onClick}>
      <img draggable={false} alt="Close" src={'/_icons/_dialog/close-button-icon.svg'} />
    </button>
  )
}
