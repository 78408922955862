import style from './common.module.css'

export type Props = {
  title?: string

  className?: string
}

export function ShowPasswordIcon({ title = 'Show password', className }: Props): JSX.Element {
  return (
    <svg
      viewBox="0 0 22 16"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      className={[className ? className : style.defaultClassName].join(' ')}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0009 8C15.0009 10.2091 13.2101 12 11.0009 12C8.79181 12 7.00095 10.2091 7.00095 8C7.00095 5.79086 8.79181 4 11.0009 4C13.2101 4 15.0009 5.79086 15.0009 8ZM13.0009 8C13.0009 9.10457 12.1055 10 11.0009 10C9.89638 10 9.00095 9.10457 9.00095 8C9.00095 6.89543 9.89638 6 11.0009 6C12.1055 6 13.0009 6.89543 13.0009 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9619 7.72322L21.0409 8L20.9619 8.27678L20.9611 8.27949L20.959 8.28683L20.9523 8.30905C20.9469 8.32716 20.9393 8.35181 20.9295 8.38257C20.9098 8.44406 20.8813 8.53008 20.8431 8.63712C20.7667 8.851 20.6512 9.15003 20.4902 9.50592C20.1693 10.2152 19.6616 11.1642 18.9123 12.1178C17.404 14.0374 14.8887 16 11.0009 16C7.11318 16 4.59787 14.0374 3.08963 12.1178C2.34033 11.1642 1.8326 10.2152 1.51171 9.50592C1.35071 9.15003 1.2352 8.851 1.15881 8.63712C1.12058 8.53008 1.09205 8.44406 1.07243 8.38257C1.06261 8.35181 1.05502 8.32716 1.04956 8.30905L1.04293 8.28683L1.04079 8.27949L1.04001 8.27678L1.03942 8.27472L0.960938 8L1.03969 7.72433L1.04001 7.72322L1.04079 7.72051L1.04293 7.71317L1.04956 7.69095C1.05502 7.67284 1.06261 7.64819 1.07243 7.61743C1.09205 7.55594 1.12058 7.46992 1.15881 7.36288C1.2352 7.149 1.35071 6.84997 1.51171 6.49408C1.8326 5.78476 2.34033 4.83583 3.08963 3.88218C4.59787 1.96259 7.11318 0 11.0009 0C14.8887 0 17.404 1.96259 18.9123 3.88218C19.6616 4.83583 20.1693 5.78476 20.4902 6.49408C20.6512 6.84997 20.7667 7.149 20.8431 7.36288C20.8813 7.46992 20.9098 7.55594 20.9295 7.61743C20.9393 7.64819 20.9469 7.67284 20.9523 7.69095L20.959 7.71317L20.9611 7.72051L20.9619 7.72322ZM3.33393 8.68158C3.20729 8.40165 3.11579 8.16733 3.0551 8C3.11579 7.83267 3.20729 7.59835 3.33393 7.31842C3.60679 6.71524 4.03656 5.91417 4.66226 5.11782C5.90402 3.53741 7.88871 2 11.0009 2C14.1132 2 16.0979 3.53741 17.3396 5.11782C17.9653 5.91417 18.3951 6.71524 18.668 7.31842C18.7946 7.59835 18.8861 7.83267 18.9468 8C18.8861 8.16733 18.7946 8.40165 18.668 8.68158C18.3951 9.28476 17.9653 10.0858 17.3396 10.8822C16.0979 12.4626 14.1132 14 11.0009 14C7.88871 14 5.90402 12.4626 4.66226 10.8822C4.03656 10.0858 3.60679 9.28476 3.33393 8.68158Z"
      />
    </svg>
  )
}
