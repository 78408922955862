import { RefCallback, useCallback, useState } from 'react'

/**
 * Hook for measure an element width
 * @description This hook measure the width of an HTML element.
 * The width includes content, padding, and border.
 * More details on the [official solution to measure a component](https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node)
 * @param elementHashCode - If this change, the width will be measured again
 * @return [width, ref] - The width of the element and the ref of the element.
 */
export function useWidth<T extends Element>(elementHashCode = ''): [number, RefCallback<T>] {
  const [width, setWidth] = useState(0)

  const ref = useCallback(
    (element) => {
      if (element !== null) {
        const width = element.getBoundingClientRect().width
        setWidth(width)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementHashCode],
  )

  return [width, ref]
}
