import { useEffect, useState } from 'react'
import throttle from 'lodash/throttle'
import { THROTTLE_TIME_IN_MS } from '@hooks/use-check-if-mobile/constants'

export default function useCheckIfMobile(mobileBreakpoint: number) {
  const [isMobile, setIsMobile] = useState(
    typeof window === 'object' ? window.innerWidth < mobileBreakpoint : false,
  )

  useEffect(() => {
    const checkIsMobile = throttle(() => {
      if (window) setIsMobile(window.innerWidth < mobileBreakpoint)
    }, THROTTLE_TIME_IN_MS)
    window.addEventListener('resize', checkIsMobile)
    return () => {
      window.removeEventListener('resize', checkIsMobile)
    }
  })

  return isMobile
}
