import { RootState } from '@root/store'
import { WhiteLabelInterface } from '@shared/interfaces/creators/white-label.interface'
import {
  selectChannelPathnameByChannelId,
  selectChannelPropByChannelPathname,
} from '@store/channel-slice/_selectors'
import { FetchStatus } from '@store/_types'
import { ChannelInterface } from '@shared/interfaces/creators/channel.interface'

/**
 * Select fetch status
 * @param organisationId
 */
export const selectStatus = ({ organisations }: RootState): FetchStatus => {
  return organisations?.fetchStatus
}

/**
 * Select organisation by organisationId
 * @param organisationId
 */
export const getOrganisationByOrgId =
  (organisationId: string) =>
  ({ organisations }: RootState) => {
    return organisations?.organisations?.find(
      (organisation) => organisation.organisationId === organisationId,
    )
  }

/**
 * Select organisation by organisationId
 * @param organisationId
 */
export const selectSuperChannelByOrgId =
  (organisationId: string) =>
  ({ organisations }: RootState): ChannelInterface => {
    const organisation = organisations?.organisations?.find(
      (organisation) => organisation.organisationId === organisationId,
    )

    if (organisation) {
      return organisation.channels.find((channel) => channel.superChannel)
    }

    return null
  }

/**
 * Select white label by channelId
 * @param channelId
 */
export const selectWhiteLabelByChannelId =
  (channelId: string) =>
  (state: RootState): WhiteLabelInterface => {
    const channelPathname = selectChannelPathnameByChannelId(channelId)(state)
    if (channelPathname) {
      const organisation =
        selectChannelPropByChannelPathname(channelPathname)('organisation')(state)
      if (organisation?.organisationId) {
        const org = getOrganisationByOrgId(organisation.organisationId)(state)
        return org?.whiteLabel
      }
    }
    return undefined
  }
