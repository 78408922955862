import style from './common.module.css'
import { HidePasswordIcon, ShowPasswordIcon } from './_icons'
import { randomId } from '../_utilities/randomId'
import { useState, KeyboardEvent as keyboardEvent } from 'react'
import { useStorage } from '../_hooks/useStorage'
import { useWidth } from '../../_hooks/deprecated-use-width'
import type { ChangeEvent } from 'react'
import type { Props } from './_types'
import { parseBoolean } from '@utils/connascence'

export function InputField({
  setValue,
  type,
  value,
  disabled,
  label,
  placeholder,
  prefix,
  storage,
  className = style.defaultClassName,
  labelClassName = style.defaultLabelClassName,
  id = randomId(label),
  errorField = false,
  errorDesc = '',
  errorVariant = 'pink',
  searchComponent,
  customInputClassName,
  onKeyDown,
  inputRef,
  ...others
}: Props): JSX.Element {
  //Featured flag
  const isNewTheme = parseBoolean(process.env.VIDZING_THEME_2024)
  const styleToUse = isNewTheme ? style.inputV2 : style.input

  // Show password button state and handler
  const [isShowPassword, setIsShowPassword] = useState(false)

  const handleClick = () => {
    setIsShowPassword(!isShowPassword)
  }

  // Save value in the localStorage or sessionStorage
  const saveInStorage = useStorage(setValue, storage)

  // Set prefix width
  const [prefixWidth, prefixRef] = useWidth(prefix ?? '')
  const cssVariables: Record<string, unknown> = {
    '--prefixWidth': prefixWidth + 'px',
  }

  const inputClassName = [
    styleToUse,
    prefix ? style.withPrefix : style.withoutPrefix,
    type === 'password' ? style.isPassword : style.isNotPassword,
    customInputClassName,
  ].join(' ')

  const wrapperInputClasses = [style.inputBox, errorField ? style.invalidEmail : null]
  if (errorVariant === 'yellow') {
    wrapperInputClasses.push(style.errorYellowVariant)
  }

  const handleChange = ({
    // currentTarget: { validity },
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setValue(value)
    saveInStorage(value)
    // TODO: add client-side validation
    // checkValidation(validity)
  }

  const handleOnKeyDown = (event: keyboardEvent<HTMLInputElement>) => {
    onKeyDown(event)
  }

  // TODO: Use a abstract Label component
  return (
    <div className={[style.wrapper, className].join(' ')}>
      {label && (
        <label className={[style.label, labelClassName].join(' ')} htmlFor={id}>
          {label}
        </label>
      )}
      <div className={wrapperInputClasses.join(' ')}>
        {/* TODO: Add autocomplete guidance */}

        <input
          autoComplete="off"
          aria-label={label ? undefined : placeholder}
          disabled={disabled}
          placeholder={placeholder}
          type={type === 'password' ? (isShowPassword ? 'text' : 'password') : type}
          className={inputClassName}
          id={id}
          value={value}
          onChange={handleChange}
          style={cssVariables}
          {...(inputRef && { ref: inputRef })}
          {...(onKeyDown && { onKeyDown: handleOnKeyDown })}
          {...others}
        />
        {errorField && errorDesc && <span className={style.errorField}>{errorDesc}</span>}
        {prefix && (
          <span className={style.span} ref={prefixRef}>
            {prefix}
          </span>
        )}
        {type === 'password' && !errorField && (
          <button className={style.button} disabled={disabled} onClick={handleClick} type="button">
            {isShowPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
          </button>
        )}
        {(type === 'search' && searchComponent) ?? null}
      </div>
      <p className={style.error}>{}</p>
    </div>
  )
}
